* {
    text-align: center;
}

#root {
    background-color: var(--background-base);
    min-height: 100vh;
}

/* COLOR SCHEME */
:root {
    --background-base: rgb(75, 75, 75);
    --hover: rgb(149, 149, 149);
    --box: rgb(154, 199, 210);
    --white: rgb(243, 243, 243);
}

/* ------------TEMPS TAB-------------- */

.tempsTitle {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    margin-top: .5rem;
    margin-bottom: 3rem;
}

.gauge {
    display: inline-block;
    border: solid;
    border-radius: .6rem;
    margin-left: .75rem;
    margin-right: .75rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.dateTime {
    border: solid;
    border-radius: .6rem;
    font-weight: bolder;
    padding: .75rem;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 1rem;
    background-color: var(--white);
}

.loading {
    border: solid;
    border-radius: .6rem;
    margin-left: 35%;
    margin-right: 35%;
    text-align: center;
    background-color: var(--white);
}

.tempsBody {
    border: solid;
    border-radius: .6rem;
    background-color: var(--white);
    padding: .5rem;
    margin: 1rem;
    margin-bottom: 5rem;
}
/* -------------NAVBAR STYLING------------- */
.navbar {
    background-color: var(--box);
    border: solid;
    border-radius: .6rem;
}

.tab {
    color: var(--white);
}

/* --------------HOME STYLING-------------- */

.container {
    border: solid;
    border-radius: .6rem;
    max-width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem;
    background-color: var(--box);
    margin-top: 3rem;
}

.homeTitle {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    margin-top: .5rem;
}

.homeBody {
    border: solid;
    border-radius: .6rem;
    background-color: var(--white);
    padding: .5rem;
    margin: 1rem;
}

.homeImg {
    border: solid;
    border-radius: .6rem;
    width: 50%;
    height: 25%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

/* -------------STREAM STYLING------------- */

.streamTitle {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
}

.snapshotImg {
    border: solid;
    border-radius: .6rem;
    margin: 2rem;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.streamBody {
    border: solid;
    border-radius: .6rem;
    background-color: var(--white);
    padding: .5rem;
    margin: 1rem;
}

/* -------------FOOTER STYLING------------- */

footer {
    margin-top: 10rem;
    padding: .75rem;
    text-align: center;
    background-color: var(--box);
    border: solid;
    border-radius: .6rem;
}

.profiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.github { 
    padding: .5rem;
}

.linkedin {
    padding: .5rem;
}

.instagram {
    padding: .5rem;
}

/* -----------ABOUT STYLING---------- */
.aboutTitle {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
}

.aboutImgCard {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border: solid;
    border-radius: .6rem;
    padding: .5rem;
    background-color: var(--background-base);
    margin-top: .5rem;
}

.aboutCard {
    border: solid;
    border-radius: .6rem;
    width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: var(--hover);
}

.aboutCardBody {
    border: solid;
    border-radius: .6rem;
    margin: 1rem;
    background-color: var(--white);
}

.aboutCardTitle {
    font-size: xx-large;
    font-weight: bolder;
    text-decoration: underline;
}

.aboutCardText {
    border: solid;
    border-radius: .6rem;
    padding: 1rem;
}


